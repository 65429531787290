import Flatpickr from 'flatpickr/dist/flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'

export default class DatePicker {
    #datePickerConfig = {
        dateFormat: "d-m-Y",
        allowInput: true,
        locale: Dutch
    }

    constructor(element) {
        // When already bind, do not bind again
        if (element._flatpickr) {
            return
        }

        let config = Object.assign({}, this.#datePickerConfig)

        if (element.dataset.maxDate) {
            config.maxDate = element.dataset.maxDate
        }

        Flatpickr(element, config)
    }

    static initAll(selector) {
        Array.from(document.querySelectorAll(selector), (element) => new DatePicker(element))
    }
}

// Example usage:
//
// <select data-toggle="field-toggle">
//      <option value="1" data-hide=".hide-when-1" data-show=".show-when-1">Option 1</option>
//      <option value="2" data-hide=".hide-when-2" data-show=".show-when-2">Option 1</option>
// </select>
//
// OR
//
// <input type="radio" name="radio" value="1" data-hide=".hide-when-1" data-show=".show-when-1">
// <input type="radio" name="radio" value="2" data-hide=".hide-when-2" data-show=".show-when-2">
//
// <div class="hide-when-1">This is hidden when option 1 is selected</div>
// <div class="show-when-1">This is shown when option 1 is selected</div>
// <div class="hide-when-2">This is hidden when option 2 is selected</div>
// <div class="show-when-2">This is shown when option 2 is selected</div>
export default class FieldToggle {
    #element

    constructor(element) {
        this.#element = element

        this.#bindEvents()

        // Trigger the first change event to ensure the correct fields are shown/hidden on page load
        this.#handleChange()
    }

    static initAll(selector) {
        Array.from(document.querySelectorAll(selector), (element) => new FieldToggle(element))
    }

    #bindEvents() {
        this.#element.addEventListener('change', this.#handleChange)
    }

    #handleChange = () => {
        let hideFieldSelector, showFieldSelector = null;

        if (this.#element.nodeName.toLowerCase() === 'select') {
            // For select elements, the element itself contains the toggle, but the selected option determines which
            // fields should be hidden or shown
            let selectedOption = this.#element.options[this.#element.selectedIndex];

            hideFieldSelector = selectedOption.dataset.hide
            showFieldSelector = selectedOption.dataset.show
        } else if (this.#element.getAttribute('type') === 'radio' && this.#element.checked) {
            // For radio buttons, the checked radio button determines which field should be hidden or shown
            hideFieldSelector = this.#element.dataset.hide
            showFieldSelector = this.#element.dataset.show
        }

        // Hide all fields that should be hidden when a selector to hide fields is provided
        if (hideFieldSelector !== null) {
            document.querySelectorAll(hideFieldSelector).forEach((element) => {
                element.classList.toggle('hidden', true);
            });
        }

        // Show all fields that should be visible when a selector to show fields is provided
        if (showFieldSelector !== null) {
            document.querySelectorAll(showFieldSelector).forEach((element) => {
                element.classList.toggle('hidden', false);
            });
        }
    }
}

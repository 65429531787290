export default class SelectText {
    #element
    #target

    constructor(element) {
        this.#element = element
        this.#target = document.querySelector(this.#element.dataset.target)

        this.#bindEvents()

        // Trigger the first change event to ensure the correct fields are shown/hidden on page load
        this.#handleChange()
    }

    static initAll(selector) {
        Array.from(document.querySelectorAll(selector), (element) => new SelectText(element))
    }

    #bindEvents() {
        this.#element.addEventListener('change', this.#handleChange)
    }

    #handleChange = () => {
        let selectedOption = this.#element.options[this.#element.selectedIndex]
        if (selectedOption.value === '') {
            return
        }

        this.#target.value = selectedOption.text
    }
}

import ApexCharts from 'apexcharts'

export default class Chart {
    constructor(element) {
        const chart = new ApexCharts(element, JSON.parse(element.dataset.chart));
        chart.render();
    }

    static initAll(selector) {
        Array.from(document.querySelectorAll(selector), (element) => new Chart(element))
    }
}

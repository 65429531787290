document.addEventListener("DOMContentLoaded", function () {
    let toggle = document.querySelector('#navbar-toggle');

    if (toggle) {
        toggle.addEventListener('click', function (e) {
            e.preventDefault();
            let overAll = document.querySelector('body');
            toggle.classList.toggle('active');
            overAll.classList.toggle('no-scroll');
        });
    }
});
